<template>
  <div class="member-manage-table">
    <div class="table">
      <div class="row thead">
        <div class="action td"></div>
        <div class="text td">成员</div>
        <div class="text td">邮箱</div>
        <div class="text td">权限</div>
      </div>
      <div
        v-for="(item, index) in members"
        :key="item.id"
        :class="['row', 'item', { default: item.default }]"
      >
        <div class="action td">
          <a-checkbox
            :disabled="item.disabled"
            :checked="item.checked"
            @change="(e) => changeCheckbox(e, index)"
          />
        </div>
        <div class="text td">
          <a-input
            size="small"
            placeholder="Username"
            :value="item.username"
            :disabled="mode !== 'create' || item.disabled"
            @change="e => changeItemInput(e, index, 'username')"
          />
        </div>
        <div class="text td">
          <a-input
            size="small"
            placeholder="Email"
            :value="item.email"
            :disabled="mode !== 'create' || item.disabled"
            @change="e => changeItemInput(e, index, 'email')"
          />
        </div>
        <div class="text td">
          <a-select
            size="small"
            placeholder="请选择"
            :value="item.user_role"
            :disabled="item.disabled"
            @change="(e) => changeItemRole(e, index, 'user_role')"
          >
            <a-select-option
              v-for="role in projectMemberRoleOptions"
              :key="role.value"
              :disabled="role.value === 'owner'"
            >
              {{ role.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PROJECT_MEMBER_ROLE_OPTIONS } from '@/utils/constant';
export default {
  name: 'memberManageTable',
  props: {
    mode: String, // create、edit、view
    initialMembers: Array,
    changeValues: Function,
  },
  data() {
    return {
      members: [...this.initialMembers],
      form: this.$form.createForm(this, { name: 'memberManageTable' }),
      emptyRecord: { username: undefined, email: undefined, user_role: undefined, checked: true },
      projectMemberRoleOptions: PROJECT_MEMBER_ROLE_OPTIONS,
    };
  },
  mounted() {
    if (this.mode === 'create') {
      this.members.push({ ...this.emptyRecord });
    }
  },
  methods: {
    changeCheckbox(e, index) {
      this.members[index].checked = e.target.checked;
      this.changeValues(this.members);
    },
    changeItemInput(e, index, key) {
      const members = this.members;
      members[index][key] = e.target.value;
      if (index === (members.length - 1) && (members[index].username || members[index].email)) {
        members.push({ ...this.emptyRecord });
      }
      this.changeValues(members.filter(item => item.username || item.email));
      this.members = members;
    },
    changeItemRole(value, index, key) {
      const members = this.members;
      members[index][key] = value;
      if (
        index === members.length - 1 &&
        (members[index].key || members[index].value)
      ) {
        members.push({ ...this.emptyRecord });
      }
      this.changeValues(members.filter((item) => item.username || item.email));
      this.members = members;
    },
    deleteItem(index) {
      let members = this.members;
      members.splice(index, 1);
      this.changeValues(members);
      this.members = members;
    },
  },
};
</script>

<style lang="scss">
.member-manage-table {
  .table {
    width: 100%;
    border-top: solid 1px var(--border-color);
    border-left: solid 1px var(--border-color);
    border-right: solid 1px var(--border-color);
    .td {
      padding: 2px 5px;
      font-size: 12px;
      height: 30px;
      color: var(--primary-color);
      border-right: solid 1px var(--border-color);
      border-bottom: solid 1px var(--border-color);
      position: relative;
      &:last-child {
        border-right: none;
      }
    }
    .thead {
      font-weight: bold;
    }
    .row {
      display: flex;
      flex-direction: row;
      .action {
        display: flex;
        width: 60px;
        padding: 7px 9px;
        justify-content: space-between;
        align-items: center;
        .mover-wrapper {
          display: inline-block;
          width: 16px;
          height: 16px;
          line-height: 16px;
        }
        .ant-form-item {
          display: inline-block;
        }
      }
      .text {
        flex: 1;
        line-height: 26px;
      }
    }
  }

  /*被拖拽对象的样式*/
  .item {
    background-color: var(--current-background-color);
    position: relative;
    .mover {
      display: none;
      cursor: move;
    }
    .close {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      position: absolute;
      right: 0;
      opacity: 0;
    }
    &:hover {
      .mover {
        display: inline-block;
      }
      .close {
        opacity: 1;
      }
    }
    &.default {
      background-color: var(--current-background-color2);
    }
  }

  /*选中样式*/
  .chosen {
    opacity: 0.9 !important;
    border: 1px solid #ede8e4 !important;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);
  }
  .ghost {
    border-top: 1px solid #e37348 !important;
  }

  /* antd样式 */
  .ant-form-item {
    margin: 0;
    height: auto;
  }
  .ant-form-item-control {
    line-height: 1;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #555;
    border-color: #555;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #555;
  }
  .ant-checkbox-checked::after {
    border-color: #555;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #a6a6a6;
    border-color: #a6a6a6 !important;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff;
    -webkit-animation-name: none;
    animation-name: none;
  }

  .ant-input {
    padding: 1px 2px;
    font-size: 12px;
    background-color: transparent;
    border-color: transparent;
    &:hover {
      background-color: #fff;
      border: 1px solid #d9d9d9;
    }
  }
  .ant-input-disabled {
    color: var(--primary-color);
    cursor: text;
    &:hover {
      background-color: transparent;
      border-color: transparent;
    }
  }
}
</style>

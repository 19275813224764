var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":_vm.initialValue ? '编辑项目' : '添加项目',"visible":this.$store.state.projectModalVisible,"destroyOnClose":true,"maskClosable":false,"footer":null,"centered":true},on:{"cancel":_vm.hideProjectModal}},[_c('a-form',{staticClass:"project-edit-modal-form padding-20",attrs:{"form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"项目名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            initialValue: _vm.initialValue ? _vm.initialValue.name : undefined,
            rules: [
              {
                required: true,
              } ],
          } ]),expression:"[\n          'name',\n          {\n            initialValue: initialValue ? initialValue.name : undefined,\n            rules: [\n              {\n                required: true,\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"项目描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'description',
          {
            initialValue: _vm.initialValue ? _vm.initialValue.description : undefined,
            rules: [
              {
                max: 200
              } ],
          } ]),expression:"[\n          'description',\n          {\n            initialValue: initialValue ? initialValue.description : undefined,\n            rules: [\n              {\n                max: 200\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"","auto-size":{ minRows: 4, maxRows: 10 }}})],1)],1),_c('div',{staticClass:"btn-group border-top"},[_c('a-button',{on:{"click":_vm.hideProjectModal}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":function($event){return _vm.debounce(_vm.handleSubmit)}}},[_vm._v(" 确定 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <a-modal
    title="导入项目"
    :visible="this.$store.state.importProjectModalVisible"
    :destroyOnClose="true"
    :maskClosable="false"
    :footer="null"
    :centered="true"
    @cancel="hideImportProjectModal"
  >
    <a-form
      class="import-project-modal-form padding-20"
      :form="form"
      :hideRequiredMark="true"
      @submit="handleSubmit"
    >
      <a-form-item label="数据来源">
        <a-select
          placeholder="请选择"
          v-decorator="[
            'source',
            {
              initialValue: 'postman_v2.1',
              rules: [
                {
                  required: true,
                  message: '请选择',
                },
              ],
            },
          ]"
        >
          <a-select-option v-for="item in sourceOptions" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="选择Json文件">
        <a-upload-dragger
          name="file"
          accept=".json"
          :showUploadList="false"
          :before-upload="beforeUpload"
          v-decorator="[
            'fileName',
            {
              rules: [
                {
                  required: true,
                  message: '请选择',
                },
              ],
            },
          ]"
        >
          <div class="upload-wrapper">
            <a-button size="small">选择文件</a-button>
            <span class="filename">{{ fileName || '未选择任何文件' }}</span>
          </div>
        </a-upload-dragger>
      </a-form-item>
    </a-form>
    <div class="btn-group border-top">
      <a-button @click="hideImportProjectModal">取消</a-button>
      <a-button type="primary" html-type="submit" @click="debounce(handleSubmit)">
        确定
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { getUUID } from '@/utils';
export default {
  name: 'projectEditModal',
  computed: {
    initialValue() {
      return this.$store.getters.getProjectModalValues;
    },
  },
  props: {
    importProjectCallback: Function
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'projectEditModalForm' }),
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
      sourceOptions: [
        { label: 'postman v2', value: 'postman_v2' },
        { label: 'postman v2.1', value: 'postman_v2.1' },
        { label: 'swagger v3', value: 'swagger_v3' },
        // { label: 'swagger v3 URL', value: 'swagger_v3_url' },
      ],
      fileName: null,
      fileContent: null, // json
    };
  },
  methods: {
    showImportProjectModal() {
      this.$store.commit('changeImportProjectModalVisible', true);
    },
    hideImportProjectModal() {
      this.$store.commit('changeImportProjectModalVisible', false);
    },
    handleRemove() {
      this.fileName = null;
    },
    beforeUpload(file) {
      const _this = this;
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function(event) {
        const fileContent = JSON.parse(event.target.result);
        _this.fileName = file.name;
        _this.fileContent = fileContent;
        _this.form.setFieldsValue({ fileName: file.name });
        _this.readJsonBySource();
      };
      return false;
    },
    readJsonBySource() {
      const source = this.form.getFieldValue('source');
      switch (source) {
        case 'postman_v2':
          this.readPostmanJsonV2();
          break;
        case 'postman_v2.1':
          this.readPostmanJsonV21();
          break;
        case 'swagger_v3':
          this.readSwaggerJson();
          break;
        case 'swagger_v3_url':
          this.readSwaggerUrl();
          break;
        default:
          break;
      }
    },
    readPostmanJsonV2() {
      // console.log(this.fileContent)
      const { userInfo } = this.$store.state;
      const sourceData = {...this.fileContent};
      const getCategoryInfo = function(arr, parent) {
        let apis = [];
        let categories = [];
        arr.forEach((record, index) => {
          if (record.item) {
            const category = {
              id: getUUID(),
              user_id: userInfo.detail.id,
              parent_id: parent ? parent.id : null, // 父级目录的id
              name: record.name,
              description: '',
              version: 1,
              sort: parseInt(index + 1),
            };
            categories.push(category);
            const result = getCategoryInfo(record.item, category);
            apis = apis.concat(result.apis);
            categories = categories.concat(result.categories);
          }
          if (record.request) {
            const api = {
              id: getUUID(),
              user_id: userInfo.detail.id,
              category_id: parent ? parent.id : null,
              name: record.name,
              status: 'DEVELOPMENT',
              sort: parseInt(index + 1),
              version: 1,
              method: record.request.method,
              url: record.request.url.raw,
              header_params: record.request.header
                ? JSON.stringify(record.request.header.map((item) => ({
                    checked: true,
                    key: item.key,
                    value: item.value,
                  })))
                : null,
              query_params: record.request.query || null,
              formdata:
                record.request.body && record.request.body.mode === 'formdata'
                  ? JSON.stringify(record.request.body.formdata.map((item) => ({
                      key: item.key,
                      value: item.value,
                      checked: true,
                    })))
                  : null,
              body_params:
                record.request.body && record.request.body.mode === 'urlencoded'
                  ? JSON.stringify(record.request.body.urlencoded.map((item) => ({
                      key: item.key,
                      value: item.value,
                      checked: true,
                    })))
                  : null,
              body_raw:
                record.request.body && record.request.body.mode === 'raw'
                  ? JSON.stringify(record.request.body.raw)
                  : null,
              body_type: record.request.body ? record.request.body.mode : null,
              raw_content_type:
                record.request.body && record.request.body.options
                  ? record.request.body.options.raw.language
                  : null,
              pre_request_raw: (() => {
                let pre_request_raw = null;
                if (record.event) {
                  const prerequest = record.event.find(
                    (item) => item.listen === 'prerequest'
                  );
                  if (prerequest) {
                    pre_request_raw = prerequest.script.exec.join('\n');
                  }
                }
                return JSON.stringify(pre_request_raw);
              })(),
              after_request_raw: (() => {
                let after_request_raw = null;
                if (record.event) {
                  const test = record.event.find(
                    (item) => item.listen === 'test'
                  );
                  if (test) {
                    after_request_raw = test.script.exec.join('\n');
                  }
                }
                return JSON.stringify(after_request_raw);
              })()
            };
            apis.push(api);
          }
        });
        return { apis, categories };
      };
      const categoryInfo = getCategoryInfo(sourceData.item);
      // console.log(categoryInfo);
      this.fileContent['auth'] = categoryInfo.auth ? { [sourceData.auth.type]: sourceData.auth.basic.username } : null;
      this.fileContent['categories'] = categoryInfo.categories;
      this.fileContent['apis'] = categoryInfo.apis;
    },
    readPostmanJsonV21() {
      // console.log(this.fileContent)
      const { userInfo } = this.$store.state;
      const sourceData = {...this.fileContent};
      const getCategoryInfo = function(arr, parent) {
        let apis = [];
        let categories = [];
        arr.forEach((record, index) => {
          if (record.item) {
            const category = {
              id: getUUID(),
              user_id: userInfo.detail.id,
              parent_id: parent ? parent.id : null, // 父级目录的id
              name: record.name,
              description: '',
              version: 1,
              sort: parseInt(index + 1),
            };
            categories.push(category);
            const result = getCategoryInfo(record.item, category);
            apis = apis.concat(result.apis);
            categories = categories.concat(result.categories);
          }
          if (record.request) {
            const api = {
              id: getUUID(),
              user_id: userInfo.detail.id,
              category_id: parent ? parent.id : null,
              name: record.name,
              status: 'DEVELOPMENT',
              sort: parseInt(index + 1),
              version: 1,
              method: record.request.method,
              url: record.request.url.raw,
              header_params: record.request.header
                ? JSON.stringify(record.request.header.map((item) => ({
                    checked: true,
                    key: item.key,
                    value: item.value,
                  })))
                : null,
              query_params: record.request.query || null,
              formdata:
                record.request.body && record.request.body.mode === 'formdata'
                  ? JSON.stringify(record.request.body.formdata.map((item) => ({
                      key: item.key,
                      value: item.value,
                      checked: true,
                    })))
                  : null,
              body_params:
                record.request.body && record.request.body.mode === 'urlencoded'
                  ? JSON.stringify(record.request.body.urlencoded.map((item) => ({
                      key: item.key,
                      value: item.value,
                      checked: true,
                    })))
                  : null,
              body_raw:
                record.request.body && record.request.body.mode === 'raw'
                  ? JSON.stringify(record.request.body.raw)
                  : null,
              body_type: record.request.body ? record.request.body.mode : null,
              raw_content_type:
                record.request.body && record.request.body.options
                  ? record.request.body.options.raw.language
                  : null,
              pre_request_raw: (() => {
                let pre_request_raw = null;
                if (record.event) {
                  const prerequest = record.event.find(
                    (item) => item.listen === 'prerequest'
                  );
                  if (prerequest) {
                    pre_request_raw = prerequest.script.exec.join('\n');
                  }
                }
                return JSON.stringify(pre_request_raw);
              })(),
              after_request_raw: (() => {
                let after_request_raw = null;
                if (record.event) {
                  const test = record.event.find(
                    (item) => item.listen === 'test'
                  );
                  if (test) {
                    after_request_raw = test.script.exec.join('\n');
                  }
                }
                return JSON.stringify(after_request_raw);
              })()
            };
            apis.push(api);
          }
        });
        return { apis, categories };
      };
      const categoryInfo = getCategoryInfo(sourceData.item);
      this.fileContent['auth'] = sourceData.auth;
      this.fileContent['categories'] = categoryInfo.categories;
      this.fileContent['apis'] = categoryInfo.apis;
    },
    readSwaggerJson() {
      // console.log(this.fileContent)
    },
    readSwaggerUrl() {
      // console.log(this.fileContent)
    },
    async saveProjectInfo() {
      const json = this.fileContent;
      const projectInfo = {
        id: getUUID(),
        name: json.info.name,
        description: json.info.description,
        team_id: this.$store.state.currentTeam.id,
        auth: json.auth || null,
        variable: json.variable ? json.variable.map(item => ({ key: item.key, value: item.value, checked: true })) : null,
        pre_request_raw: json.pre_request_raw || null,
        after_request_raw: json.after_request_raw || null,
        apis: [],
        categories: [],
      };
      if (json.event) {
        const pre_request_raw = json.event.find(item => item.listen === 'prerequest');
        const after_request_raw = json.event.find(item => item.listen === 'test');
        if (pre_request_raw) {
          projectInfo.pre_request_raw = pre_request_raw.script.exec.join('\n');
        }
        if (after_request_raw) {
          projectInfo.after_request_raw = after_request_raw.script.exec.join('\n');
        }
      }
      if (json.apis) {
        projectInfo.apis = json.apis.map(item => ({ ...item, project_id: projectInfo.id }));
      }
      if (json.categories) {
        projectInfo.categories = json.categories.map(item => ({ ...item, project_id: projectInfo.id }));
      }
      await this.$store.dispatch('saveImportProject', projectInfo);
      this.hideImportProjectModal();
      if (this.importProjectCallback) {
        this.importProjectCallback();
      }
    },
    handleSubmit() {
      this.form.validateFields((error) => {
        if (!error) {
          this.saveProjectInfo();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.import-project-modal-form {
  .upload-wrapper {
    .filename {
      margin-left: 10px;
      font-size: 12px;
      color: var(--primary-color);
    }
  }
  .ant-upload.ant-upload-drag {
    background-color: var(--current-background-color);
    border: 1px solid var(--border-color);
    color: var(--primary-color);
    &:hover {
      background-color: var(--hover-color);
    }
    &:focus {
      background-color: var(--current-background-color);
    }
    .ant-upload {
      padding: 4px 10px;
      text-align: left;
    }
  }
  .ant-btn {
    font-size: 12px;
    color: var(--primary-color);
    background-color: var(--button-bg);
    border-color: var(--button-bg);
    &:hover {
      color: var(--button-hover-color);
      background-color: var(--button-hover-bg);
      border-color: var(--button-hover-bg);
    }
  }
}
</style>

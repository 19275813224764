<template>
  <div class="team-list-member">
    <div class="text-align-right margin-bottom">
      <a-button
        type="primary"
        icon="plus"
        @click="changeActiveKey('add-member')"
      >
        邀请成员
      </a-button>
    </div>
    <a-table
      rowKey="user_id"
      :data-source="members"
      :columns="columns"
      :pagination="{ pageSize: 5, hideOnSinglePage: true }"
    >
      <span slot="user_role" slot-scope="text">{{ teamMemberRole[text] }}</span>
      <span slot="status" slot-scope="text">
        <a-badge status="warning" v-if="text === 'PENDING'" />
        <a-badge status="success" v-if="text === 'APPROVED'" />
        <a-badge status="error" v-if="text === 'REJECTED'" />
        {{ teamMemberStatus[text] }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a-dropdown
          :trigger="['click']"
          placement="bottomRight"
          overlayClassName="setting-dropdown"
        >
          <a-button
            type="link"
            icon="ellipsis"
            @click="(e) => settingMemberItem(e, record)"
          ></a-button>
          <a-menu slot="overlay" class="extra-menu">
            <a-menu-item>
              <a @click="editRecord">
                <a-icon type="edit" />
                <span>编辑</span>
              </a>
            </a-menu-item>
            <a-menu-item v-if="record.user_role !== 'owner'">
              <a @click="deleteConfirm">
                <a-icon type="delete" />
                <span>删除</span>
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <a-modal
      title="编辑成员"
      wrapClassName="editMemberModal"
      :width="800"
      :maskClosable="false"
      :visible="editModalVisible"
      :confirm-loading="confirmLoading"
      okText="确定"
      cancelText="取消"
      @ok="handleOkEditMember"
      @cancel="handleCancelEditMember"
    >
      <div class="formWrapper">
        <a-form
          ref="editForm"
          :form="form"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-form-item label="成员名称">
            <a-input
              :disabled="true"
              v-decorator="[
                'username',
                {
                  initialValue: this.currentMember
                    ? this.currentMember.username
                    : undefined,
                  rules: [{ required: true, message: '名称不能为空' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="邮箱">
            <a-input
              :disabled="true"
              v-decorator="[
                'email',
                {
                  initialValue: this.currentMember
                    ? this.currentMember.email
                    : undefined,
                  rules: [{ required: true, message: '邮箱不能为空' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="手机号">
            <a-input
              v-decorator="[
                'mobile',
                {
                  initialValue: this.currentMember
                    ? this.currentMember.mobile
                    : undefined,
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="权限">
            <a-input
              :disabled="true"
              v-decorator="[
                'user_role',
                {
                  initialValue: this.currentMember
                    ? this.currentMember.user_role
                    : undefined,
                  rules: [{ required: true, message: '权限不能为空' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="状态">
            <a-select 
              placeholder="请选择"
              :disabled="this.currentMember && this.currentMember.status !== 'PENDING'"
              v-decorator="[
                'status',
                {
                  initialValue: this.currentMember
                    ? this.currentMember.status
                    : undefined,
                  rules: [{ required: true, message: '状态不能为空' }],
                },
              ]"
            >
              <a-select-option
                v-for="item in teamMemberStatusOptions"
                :key="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  TEAM_MEMBER_ROLE,
  TEAM_MEMBER_STATUS,
  TEAM_MEMBER_STATUS_OPTIONS,
} from '@/utils/constant';
export default {
  name: 'teamListMember',
  components: {},
  props: {
    members: Array,
    changeActiveKey: Function,
    saveTeamMember: Function,
    deleteTeamMember: Function,
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'editMember' }),
      teamMemberRole: TEAM_MEMBER_ROLE,
      teamMemberStatus: TEAM_MEMBER_STATUS,
      teamMemberStatusOptions: TEAM_MEMBER_STATUS_OPTIONS,
      columns: [
        {
          title: '成员',
          dataIndex: 'username',
        },
        {
          title: '邮箱',
          dataIndex: 'email',
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
        },
        {
          title: '成员权限',
          dataIndex: 'user_role',
          scopedSlots: { customRender: 'user_role' },
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
        },
      ],
      currentMember: null,
      confirmLoading: false,
      editModalVisible: false,
    };
  },
  methods: {
    showMemberSettingModal(record) {
      // console.log(record)
    },
    settingMemberItem(e, record) {
      this.currentMember = record;
      e.stopPropagation();
    },
    editRecord() {
      this.editModalVisible = true;
    },
    handleOkEditMember() {
      const form = this.$refs.editForm.form;
      form.validateFields((err, values) => {
        if (!err) {
          const member = {
            ...this.currentMember,
            ...values,
          };
          this.saveTeamMember(member);
          this.editModalVisible = false;
          form.resetFields();
        }
      });
    },
    handleCancelEditMember() {
      this.editModalVisible = false;
      this.confirmLoading = false;
    },
    deleteConfirm() {
      const _this = this;
      const currentMember = this.currentMember;
      this.$confirm({
        title: '删除成员',
        content: '确定移除该成员?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          _this.deleteTeamMember(currentMember);
        },
      });
    },
  },
};
</script>

<style lang="scss">
.team-list-member {
}
.editMemberModal {
  .formWrapper {
    padding-top: 40px;
    min-height: 150px;
  }
}
</style>

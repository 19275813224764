<template>
  <div class="param-table">
    <div class="table">
      <div class="row thead">
        <div class="action td"></div>
        <div class="text td">KEY</div>
        <div class="text td">VALUE</div>
        <div class="text td">DESCRIPTION</div>
      </div>
      <draggable
        v-model="params"
        ghostClass="ghost"
        chosenClass="chosen"
        forceFallback="true"
        animation="500"
        handle=".mover"
        filter=".default"
        :move="onMove"
        :disabled="disabledDrag"
        @start="onStart"
        @end="onEnd"
      >
        <div
          v-for="(item, index) in params"
          :key="index"
          :class="['row', 'item', { default: item.default }]"
        >
          <div class="action td">
            <span class="mover-wrapper">
              <a-icon
                type="menu"
                class="mover"
                v-if="!item.default && (item.key || item.value || item.description)"
              />
            </span>
            <a-checkbox
              v-if="item.key || item.value || item.description"
              :disabled="item.disabled"
              :checked="item.checked"
              @change="value => changeItemCheckbox(value, index)"
            />
          </div>
          <div class="text td">
            <a-auto-complete
              v-if="autoComplete"
              size="small"
              placeholder="Key"
              :data-source="headerKeys"
              :value="item.key"
              :disabled="item.default"
              @change="value => changeItemAutoComplete(value, index, 'key')"
              @search="filterHeaderKeys"
              @select="value => selectHeaderKeys(value, index, 'key')"
            />
            <a-input 
              v-if="!autoComplete"
              size="small"
              placeholder="Key"
              :value="item.key"
              :disabled="item.default"
              @change="e => changeItemInput(e, index, 'key')"
            />
          </div>
          <div class="text td">
            <a-auto-complete
              v-if="autoComplete"
              size="small"
              placeholder="Key"
              :data-source="headerValues"
              :value="item.value"
              :disabled="item.default"
              @change="value => changeItemAutoComplete(value, index, 'value')"
              @search="filterHeaderKeys"
              @select="value => selectHeaderValues(value, index, 'value')"
            />
            <a-input 
              v-if="!autoComplete"
              size="small"
              placeholder="Value"
              :value="item.value"
              :disabled="item.default"
              @change="e => changeItemInput(e, index, 'value')"
            />
          </div>
          <div class="text td">
            <a-input 
              size="small"
              :placeholder="item.default ? '' : 'Description'"
              :value="item.description"
              :disabled="item.default"
              @change="e => changeItemInput(e, index, 'description')"
            />
            <span 
              class="close" 
              v-if="!item.default && (index < params.length - 1)" 
              @click="deleteItem(index)"
            >
              <a-icon type="close" />
            </span>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { HEADER_KEYS, HEADER_VALUES } from '@/utils/constant';
export default {
  name: 'paramTable',
  components: {
    draggable,
  },
  props: {
    autoComplete: Boolean,
    defaultParams: Array,
    changeValues: Function,
  },
  data() {
    return {
      params: [...this.defaultParams],
      form: this.$form.createForm(this, { name: 'paramTable' }),
      drag: false, // 是否正在拖拽
      disabledDrag: false, // 是否允许拖拽，true: input不可输入；false: input可输入。
      emptyRecord: { key: undefined, value: undefined, description: undefined, checked: true },
      headerKeys: HEADER_KEYS,
      headerValues: HEADER_VALUES,
    };
  },
  created() {
    this.params.push({ ...this.emptyRecord });
  },
  methods: {
    onMove(e) {
      if (e.relatedContext.element.default) {
        return false;
      }
      return true;
    },
    //开始拖拽事件
    onStart() {
      this.drag = true;
    },
    //拖拽结束事件
    onEnd() {
      this.drag = false;
    },
    changeItemCheckbox(e, index) {
      const params = this.params;
      params[index].checked = e.target.checked;
      this.changeValues(params);
      this.params = params;
    },
    filterHeaderKeys(value) {
      const headerKeys = value ? this.headerKeys.filter(item => item.includes(value)) : HEADER_KEYS;
      this.headerKeys = headerKeys;
    },
    filterHeaderValues(value) {
      const headerValues = value ? this.headerValues.filter(item => item.includes(value)) : HEADER_VALUES;
      this.headerValues = headerValues;
    },
    selectHeaderKeys(value, index, key) {
      const params = this.params;
      params[index][key] = value;
      if (index === (params.length - 1) && (params[index].key || params[index].value)) {
        params.push({ ...this.emptyRecord });
      }
      this.changeValues(params.filter(item => item.key || item.value));
      this.params = params;
    },
    changeItemAutoComplete(value, index, key) {
      const params = this.params;
      params[index][key] = value;
      if (index === (params.length - 1) && (params[index].key || params[index].value)) {
        params.push({ ...this.emptyRecord });
      }
      this.changeValues(params.filter(item => item.key || item.value));
      this.params = params;
    },
    changeItemInput(e, index, key) {
      const params = this.params;
      params[index][key] = e.target.value;
      if (index === (params.length - 1) && (params[index].key || params[index].value)) {
        params.push({ ...this.emptyRecord });
      }
      this.changeValues(params.filter(item => item.key || item.value));
      this.params = params;
    },
    deleteItem(index) {
      let params = this.params;
      params.splice(index, 1);
      this.changeValues(params);
      this.params = params;
    },
  },
};
</script>

<style lang="scss">
.param-table {
  .table {
    width: 100%;
    border-top: solid 1px var(--border-color);
    .td {
      padding: 2px 5px;
      font-size: 12px;
      height: 30px;
      color: var(--primary-color);
      border-right: solid 1px var(--border-color);
      border-bottom: solid 1px var(--border-color);
      position: relative;
      &:last-child {
        border-right: none;
      }
    }
    .thead {
      font-weight: bold;
    }
    .row {
      display: flex;
      flex-direction: row;
      .action {
        display: flex;
        width: 60px;
        padding: 7px 9px;
        justify-content: space-between;
        align-items: center;
        .mover-wrapper {
          display: inline-block;
          width: 16px;
          height: 16px;
          line-height: 16px;
        }
        .ant-form-item {
          display: inline-block;
        }
      }
      .text {
        flex: 1;
        line-height: 26px;
      }
    }
  }

  /*被拖拽对象的样式*/
  .item {
    background-color: var(--current-background-color);
    position: relative;
    .mover {
      display: none;
      cursor: move;
    }
    .close {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      position: absolute;
      right: 0;
      opacity: 0;
    }
    &:hover {
      .mover {
        display: inline-block;
      }
      .close {
        opacity: 1;
      }
    }
    &.default {
      background-color: var(--current-background-color2);
    }
  }

  /*选中样式*/
  .chosen {
    opacity: .9 !important;
    border: 1px solid #ede8e4 !important;
    box-shadow: 0 2px 10px 2px rgba(0,0,0,.1);
  }
  .ghost {
    border-top: 1px solid #e37348 !important;
  }

  /* antd样式 */
  .ant-form-item {
    margin: 0;
    height: auto;
  }
  .ant-form-item-control {
    line-height: 1;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #555;
    border-color: #555;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #555;
  }
  .ant-checkbox-checked::after {
    border-color: #555;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #a6a6a6;
    border-color: #a6a6a6 !important;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff;
    -webkit-animation-name: none;
    animation-name: none;
  }

  .ant-input {
    padding: 1px 2px;
    font-size: 12px;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    &:hover {
      background-color: #fff;
      border: 1px solid #d9d9d9;
    }
    &.ant-input-disabled {
      color: var(--primary-color);
      cursor: text;
      &:hover {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  .ant-select {
    width: 100%;
    .ant-input {
      background-color: transparent;
      border-color: transparent;
      &:hover, &:focus {
        background-color: #fff;
        border: 1px solid #d9d9d9;
      }
    }
    &.ant-select-disabled {
      .ant-select-selection {
        background-color: transparent;
      }
      .ant-input {
        background-color: transparent;
        border-color: transparent;
        color: var(--primary-color);
        cursor: text;
        &:hover {
          background-color: transparent;
          border-color: transparent;
        }
      }
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-team-add manage-team-content"},[_c('div',{staticClass:"form-wrapper padding-20"},[_c('a-form',{staticClass:"api-edit-modal-form",attrs:{"form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.addTeam}},[_c('a-form-item',{attrs:{"label":"添加团队"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                {
                  required: true,
                } ],
            } ]),expression:"[\n            'name',\n            {\n              rules: [\n                {\n                  required: true,\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"团队名称"}})],1),(_vm.members)?_c('a-form-item',{attrs:{"label":"团队成员"}},[_c('member-manage-table',{ref:"paramTable",attrs:{"mode":"view","initialMembers":_vm.members,"changeValues":_vm.changeValues}})],1):_vm._e()],1)],1),_c('div',{staticClass:"btn-group border-top"},[_c('a-button',{on:{"click":function($event){return _vm.changeActiveKey('list')}}},[_vm._v("返回")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.debounce(_vm.addTeam)}}},[_vm._v("保存")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="manage-team-list">
    <div class="manage-team-content">
      <!-- <p class="tip-wrapper"></p> -->
      <div class="left">
        <a-menu
          class="bg-color"
          mode="vertical"
          v-model="menuSelectKey"
          @click="handleClickMenu"
        >
          <a-menu-item v-for="team in $store.state.teams" :key="team.id">
            <span>{{ team.name }}</span>
          </a-menu-item>
          <a-menu-item>
            <a @click="changeActiveKey('add')">
              <a-icon type="plus" />
              <span>新建团队</span>
            </a>
          </a-menu-item>
        </a-menu>
      </div>
      <div class="right padding-20">
        <a-tabs :default-active-key="activeKey || '1'" :animated="false">
          <a-tab-pane key="1" tab="团队项目">
            <team-list-project
              :changeActiveKey="changeActiveKey"
              :projects="currentProjects"
              :teamMembers="currentMembers"
              :currentTeam="currentTeam"
              :clickNewProject="clickNewProject"
              :saveProject="saveProject"
              :deleteProject="deleteProject"
            />
          </a-tab-pane>
          <a-tab-pane key="2" tab="成员/权限" force-render>
            <team-list-member
              :changeActiveKey="changeActiveKey"
              :members="currentMembers"
              :deleteTeamMember="deleteTeamMember"
              :saveTeamMember="saveTeamMember"
            />
          </a-tab-pane>
          <a-tab-pane key="3" tab="团队设置">
            <team-list-setting
              :changeActiveKey="changeActiveKey"
              :initialValue="currentTeam"
              :deleteTeam="deleteTeam"
            />
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <!-- <div class="btn-group border-top">
      <a-button @click="closeTeamManageModal" type="link">取消</a-button>
      <a-button @click="changeActiveKey('add')" type="primary">新建团队</a-button>
    </div> -->

    <import-project-modal :importProjectCallback="importProjectCallback"></import-project-modal>
  </div>
</template>

<script>
// import { ENV, Storage } from '@/utils';
import TeamListProject from './TeamListProject.vue';
import TeamListMember from './TeamListMember.vue';
import TeamListSetting from './TeamListSetting.vue';
import ImportProjectModal from '@/components/modal/ImportProjectModal.vue';
export default {
  name: 'manageTeamList',
  components: {
    TeamListProject,
    TeamListMember,
    TeamListSetting,
    ImportProjectModal,
  },
  props: {
    activeKey: String,
    changeActiveKey: Function,
    closeTeamManageModal: Function,
    changeTeamCallback: Function,
    createNewProject: Function,
  },
  data() {
    return {
      currentTeam: null, // 当前选中的团队
      currentProjects: [],
      currentMembers: [],
      menuSelectKey: [],
    };
  },
  mounted() {
    const currentProjects = this.$store.state.projects;
    this.currentTeam = this.$store.state.currentTeam;
    this.menuSelectKey = this.currentTeam ? [this.currentTeam.id] : [];
    this.currentProjects = currentProjects;
    this.changeTeamCallback(this.currentTeam);
    this.queryTeamMembers(this.currentTeam);
  },
  methods: {
    async handleClickMenu({ key }) {
      const teams = this.$store.state.teams;
      const currentTeam = teams.find((team) => team.id === key);
      this.menuSelectKey = [key];
      if (currentTeam) {
        this.currentTeam = { ...currentTeam };
        this.currentProjects = await this.$store.dispatch(
          'queryProjects',
          { team_id: currentTeam.id }
        );
        this.changeTeamCallback(this.currentTeam);
        this.queryTeamMembers(currentTeam);
      }
    },
    async queryTeamMembers(currentTeam) {
      const currentMembers = currentTeam.members
        ? await this.$store.dispatch('getTeamMembers', currentTeam.id)
        : [];
      this.currentMembers = currentMembers;
    },
    clickNewProject() {
      this.changeActiveKey('add-project');
      this.createNewProject(this.currentMembers);
    },
    async refreshProjects(projects, project) {
      const oldCurrentProject = this.$store.state.currentProject;
      const newCurrentProject = projects[0];
      this.currentProjects = projects;
      this.$store.commit('setProjects', projects);
      this.$store.commit('setApiTabs', []);
      this.$store.commit('setCurrentProject', newCurrentProject);
      // 如果删除的是当前项目，则需要刷新相关数据
      if (project.id === oldCurrentProject.id) {
        // 刷新categorys
        if (projects.length) {
          await this.$store.dispatch('getCategoryList');
          this.$store.dispatch('getHistoryList');
        } else {
          this.$store.commit('clearCategoryList');
        }
      }
    },
    refreshMembers(members) {
      this.currentMembers = members;
    },
    async createProject(values) {
      await this.$store.dispatch('addProject', values);
    },
    async saveProject(values, action) {
      if (action === 'create') {
        try {
          await this.$store.dispatch('addProject', values);
          const { data: projects } = await this.$store.dispatch('getProjectList', { team: this.currentTeam.id });
          this.currentProjects = projects;
          this.$store.commit('setProjects', projects);
        } catch (e) {
          console.error(e);
        }
      } else {
        await this.$store.dispatch('saveProjectInfo', values);
        if (this.$store.state.currentProject.id === values.id) {
          const projectInfo = {
            ...this.$store.state.currentProject,
            ...values,
          };
          const projects = this.currentProjects;
          projects.forEach((project, index) => {
            if(project.id === values.id) {
              projects[index] = { ...project, ...values };
            }
          });
          this.currentProjects = [...projects];
          this.$store.commit('setCurrentProject', projectInfo);
        }
      }
    },
    async deleteProject(project) {
      const res = await this.$store.dispatch('deleteProject', project.id);
      if (res.code === 0) {
        const projects = this.currentProjects.filter(
          (item) => item.id !== project.id
        );
        this.refreshProjects(projects, project);
      }
    },
    async saveTeamMember(member) {
      const res = await this.$store.dispatch('saveTeamMember', {
        team_id: this.currentTeam.id,
        data: member,
      });
      if (res.code === 0) {
        // 更新members
        this.currentMembers = res.data;
      }
    },
    async deleteTeamMember(record) {
      const res = await this.$store.dispatch('deleteTeamMember', {
        team_id: this.currentTeam.id,
        member_id: record.user_id,
      });
      if (res.code === 0) {
        this.refreshMembers(res.data);
      }
    },
    // 解散团队
    async deleteTeam() {
      const teamId = this.currentTeam.id;
      const res = await this.$store.dispatch('deleteTeam', teamId);
      if (res.code === 0) {
        const teams = this.$store.state.teams;
        const newCurrentTeam = teams ? teams[0] : null;
        this.currentTeam = newCurrentTeam;
        this.menuSelectKey = this.currentTeam ? [this.currentTeam.id] : null;
        if (teamId === this.$store.state.currentTeam.id) {
          this.$store.commit('setCurrentTeam', newCurrentTeam);
        }
      }
    },
    async importProjectCallback() {
      const { data: projects } = await this.$store.dispatch('getProjectList', { team: this.currentTeam.id });
      this.currentProjects = projects;
      this.$store.commit('setProjects', projects);
    }
  },
};
</script>

<style lang="scss">
.manage-team-list {
  overflow: hidden;
  .left {
    width: 150px;
    height: 100%;
    float: left;
    ul {
      height: 100%;
      overflow-y: auto;
    }
  }
  .right {
    margin-left: 150px;
    height: 100%;
    overflow-y: auto;
  }
}
</style>

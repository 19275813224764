<template>
  <a-popover
    title=""
    placement="bottomRight"
    trigger="click"
    arrowPointAtCenter
    v-model="popoverVisible"
  >
    <template slot="content">
      <messages-popover-content></messages-popover-content>
    </template>

    <a-tooltip 
      title="消息" 
      placement="bottom"
      v-model="tooltipVisible"
    >
      <a-button type="link">
        <a-icon type="bell" />
      </a-button>
    </a-tooltip>
  </a-popover>
</template>

<script>
import MessagesPopoverContent from './MessagesPopoverContent.vue';
export default {
  name: 'messagePopover',
  components: {
    MessagesPopoverContent
  },
  props: {
    
  },
  data() {
    return {
      popoverVisible: false,
      tooltipVisible: false,
    }
  },
  watch: {
    popoverVisible(value) {
      if (value) {
        this.tooltipVisible = false;
      }
    }
  }
}
</script>

<style lang="scss">

</style>

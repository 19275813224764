<template>
  <a-modal
    title="管理团队"
    wrapClassName="manage-team-modal"
    :width="800"
    :footer="null"
    :centered="true"
    :destroyOnClose="true"
    :maskClosable="false"
    :visible="this.$store.state.teamManageModalVisible"
    @cancel="closeTeamManageModal"
  >
    <div class="manage-team-modal-container">
      <team-list 
        ref="teamList" 
        :changeActiveKey="changeActiveKey" 
        :closeTeamManageModal="closeTeamManageModal"
        :changeTeamCallback="changeTeamCallback"
        :createNewProject="createNewProject"
      />
      <div v-if="artiveKey === 'add'" class="manage-team-modal-edit">
        <team-add :changeActiveKey="changeActiveKey" />
      </div>
      <!-- <div v-if="artiveKey === 'add-project'" class="manage-team-modal-edit">
        <team-add-project :changeActiveKey="changeActiveKey" :saveProject="saveProject" :selectedProject="selectedProject" />
      </div> -->
      <div v-if="artiveKey === 'add-member'" class="manage-team-modal-edit">
        <team-add-member :changeActiveKey="changeActiveKey" :initialValue="selectedTeam" />
      </div>
    </div>
  </a-modal>
</template>

<script>
import TeamList from './TeamList.vue';
import TeamAdd from './TeamAdd.vue';
// import TeamAddProject from './TeamAddProject.vue';
import TeamAddMember from './TeamAddMember.vue';
export default {
  name: 'teamManageModal',
  components: {
    TeamList,
    TeamAdd,
    // TeamAddProject,
    TeamAddMember,
  },
  data() {
    return {
      artiveKey: 'list',
      selectedTeam: null,
      selectedProject: null,
    };
  },
  methods: {
    closeTeamManageModal() {
      this.$store.commit('changeTeamManageModalVisible', false);
    },
    changeActiveKey(key) {
      this.artiveKey = key;
    },
    changeTeamCallback(team) {
      this.selectedTeam = team;
    },
    createNewProject(members) {
      this.selectedProject = {
        name: '',
        members,
        private: 1
      }
    },
    async saveProject(newProject) {
      const res = await this.$store.dispatch('addProject', newProject);
      if (res.code === 0) {
        const { data: projects } = await this.$store.dispatch('getProjectList', { team: newProject.team_id });
        this.$refs.teamList.refreshProjects(projects);
        this.changeActiveKey('list');
      }
    },
    // async addMember(newMember) {
    //   const members = await this.$store.dispatch('addTeamMember', { team_id: this.selectedTeam.id, data: newMember });
    //   await this.$store.dispatch('showNotice', { message: '邀请已发出' });
    //   this.$refs.teamList.refreshMembers(members);
    //   this.changeActiveKey('list');
    // }
  },
};
</script>

<style lang="scss">
.manage-team-modal {
  .manage-team-modal-container {
    position: relative;
  }
  .manage-team-modal-edit {
    width: 100%;
    height: 100%;
    background-color: var(--current-background-color);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
  }
  .manage-team-content {
    height: 540px;
  }
}
</style>

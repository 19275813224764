<template>
  <div class="theme-setting">
    <h4>外观</h4>
    <div class="section">
      <p>
        <span>背景: </span>
        <span>{{ bgOptions[theme.bg].name }}</span>
      </p>
      <p>
        <ul>
          <li 
            v-for="item in Object.values(bgOptions)"
            :key="item.key"
            :class="{selected: theme.bg === item.key}"
            @click="changeThemeBg(item.key)"
          >
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </p>
    </div>
    <div class="section">
      <p>
        <span>主色: </span>
        <span>{{ brandOptions[theme.brand].name }}</span>
      </p>
      <ul>
        <li 
          v-for="item in Object.values(brandOptions)"
          :key="item.key"
          :class="{selected: theme.brand === item.key}"
          @click="changeThemeBrand(item.key)"
        >
          <span :style="{ backgroundColor: item.color }"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ENV, Storage } from '@/utils';
export default {
  name: 'themeSetting',
  props: {
    
  },
  data() {
    return {
      bgOptions: {
        "light": { key: "light", name: "亮"},
        "dark": { key: "dark", name: "暗"},
      },
      brandOptions: {
        "blue": { key: "blue", name: "蓝色", color: '#0071BC'},
      },
      theme: {
        bg: 'light',
        brand: 'blue',
      }
    }
  },
  created() {
    const defaultTheme = this.$store.state.theme;
    if (defaultTheme) {
      this.theme = defaultTheme;
    }
  },
  methods: {
    changeThemeBg(value) {
      this.theme.bg = value;
      window.document.documentElement.setAttribute('data-theme', value);
      Storage.set(ENV.storage.theme, this.theme);
      this.$store.commit('setTheme', this.theme);
    },
    changeThemeBrand(value) {
      this.theme.brand = value;
    }
  }
}
</script>

<style lang="scss">
  .theme-setting {
    .section {
      margin-top: 25px;
    }
    ul {
      li {
        display: inline-block;
        margin-right: 20px;
        padding: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;
        &.selected {
          background: #f7f7f7;
        }
        span {
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }
  }
</style>

<template>
  <div class="proxy-setting">
    <h4>网络代理</h4>
  </div>
</template>

<script>
export default {
  name: 'proxySetting',
  props: {
    
  },
  data() {
    return {
      
    }
  }
}
</script>

<style lang="scss">
  .proxy-setting {
    
  }
</style>

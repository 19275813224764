<template>
  <div class="team-add-member manage-team-content">
    <div class="form-wrapper">
      <a-tabs
        :default-active-key="inviteType"
        :animated="false"
        @change="changeInviteType"
      >
        <a-tab-pane key="link" tab="链接邀请">
          <ul class="tip-wrapper margin-bottom">
            <li>
              当前链接需要审核，受邀方接受邀请后需要企业管理员审核通过后才可进入企业
            </li>
            <li>
              受邀方接受邀请进入企业后的身份为普通成员”
            </li>
          </ul>
          <a-form class="api-edit-modal-form">
            <a-form-item label="成员角色">
              <a-select
                :value="inviteMemberRole1"
                @change="changeInviteMemberRole1"
                placeholder="请选择"
                v-decorator="[
                  'user_role',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择成员角色',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="role in teamMemberRoleOptions"
                  :key="role.value"
                  :disabled="role.value === 'owner'"
                >
                  {{ role.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="邀请链接">
              <a-input-group compact>
                <a-input
                  placeholder="请输入用户邮箱"
                  :value="inviteUrl"
                  :disabled="true"
                  style="width: 85%"
                />
                <a-button
                  type="primary"
                  style="width: 15%"
                  icon="copy"
                  v-clipboard:copy="inviteUrl"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  >复制链接</a-button
                >
              </a-input-group>
            </a-form-item>
            <a-form-item>
              <a-checkbox
                :checked="requiredApproval1"
                @change="handleChangeRequiredApproval1"
              >
                需要管理员审核
              </a-checkbox>
            </a-form-item>
          </a-form>
        </a-tab-pane>

        <a-tab-pane key="email" tab="邮箱邀请" force-render>
          <ul class="tip-wrapper margin-bottom">
            <li>
              系统将会给下方邮箱地址分别发送一封包含邀请链接的邮件。
            </li>
            <li>
              邮件链接需要审核，受邀方接受邀请后需要企业管理员审核通过后才可进入团队
            </li>
            <li>受邀方接受邀请进入企业后的身份为“普通成员”</li>
          </ul>
          <a-form
            class="api-edit-modal-form"
            :form="form"
            @submit="saveTeamMember"
          >
            <a-form-item label="成员角色">
              <a-select
                placeholder="请选择"
                v-decorator="[
                  'user_role',
                  {
                    initialValue: 'user',
                    rules: [
                      {
                        required: true,
                        message: '请选择成员角色',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="role in teamMemberRoleOptions"
                  :key="role.value"
                  :disabled="role.value === 'owner'"
                >
                  {{ role.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="邮箱（可输入多个，用英文 “,” 分隔）">
              <a-input
                placeholder="请输入用户邮箱"
                autocomplete="off"
                v-decorator="[
                  'email',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入用户邮箱',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item>
              <a-checkbox
                :checked="requiredApproval2"
                @change="handleChangeRequiredApproval2"
              >
                需要管理员审核
              </a-checkbox>
            </a-form-item>
          </a-form>
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="btn-group border-top">
      <a-button @click="changeActiveKey('list')">返回</a-button>
      <a-button
        @click="debounce(saveTeamMember)"
        type="primary"
        v-if="inviteType === 'email'"
        >邀请</a-button
      >
    </div>
  </div>
</template>

<script>
import { TEAM_MEMBER_ROLE_OPTIONS } from '@/utils/constant';
export default {
  name: 'teamAddMember',
  props: {
    changeActiveKey: Function,
    // addMember: Function,
    initialValue: Object,
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'addEnvForm' }),
      timer: null,
      teamMemberRoleOptions: TEAM_MEMBER_ROLE_OPTIONS,
      userInfo: null,
      inviteType: 'link',

      inviteUrl: null,
      inviteMemberRole1: 'user',
      requiredApproval1: true,

      inviteMemberRole2: 'user',
      requiredApproval2: true,
    };
  },
  mounted() {
    this.getInviteUrl1();
  },
  methods: {
    changeInviteType(key) {
      this.inviteType = key;
    },

    // link
    getInviteUrl1() {
      const params = {
        inviteUserId: this.$store.state.userInfo.detail.id, // 邀请发起人
        teamId: this.initialValue.id, // 团队id
        memberRole: this.inviteMemberRole1, // 成员角色
        requiredApproval: this.requiredApproval1, // 是否需要审批
      };
      this.inviteUrl = `${
        window.location.origin
      }/user/join?invite_code=${window.btoa(JSON.stringify(params))}`;
    },
    changeInviteMemberRole1(value) {
      this.inviteMemberRole1 = value;
      this.getInviteUrl1();
    },
    handleChangeRequiredApproval1(e) {
      this.requiredApproval1 = e.target.checked;
      this.getInviteUrl1();
    },
    onCopy() {
      this.$message.success('复制成功！');
    },
    onError() {
      this.$message.error('抱歉，复制失败！');
    },

    // email
    getInviteUrl2() {
      const params = {
        inviteUserId: this.$store.state.userInfo.detail.id, // 邀请发起人
        teamId: this.initialValue.id, // 团队id
        memberRole: this.inviteMemberRole2, // 成员角色
        requiredApproval: this.requiredApproval2, // 是否需要审批
      };
      return `${window.location.origin}/user/join?invite_code=${window.btoa(
        JSON.stringify(params)
      )}`;
    },
    changeInviteMemberRole2(value) {
      this.inviteMemberRole2 = value;
    },
    handleChangeRequiredApproval2(e) {
      this.requiredApproval2 = e.target.checked;
    },
    validateEmail(value) {
      let emailCheck = true;
      const reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      const emails = value.split(',');
      emails.forEach(email => {
        if (email && !reg.test(email)) {
          emailCheck = false;
          return false;
        };
      });
      return emailCheck;
    },
    changeEmail(e) {
      const value = e.target.value;
      if (!value) {
        return;
      }
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        const { data } = await this.$store.dispatch('searchUser', {
          email: value,
        });
        if (data) {
          if (data.id === this.$store.state.userInfo.detail.id) {
            errorMessage = '不能添加自己';
            this.form.setFields({
              email: {
                value,
                errors: [new Error('不能添加自己')],
              },
            });
          } else {
            this.userInfo = data; // 暂存查询的结果
          }
        } else {
          this.form.setFields({
            email: {
              value,
              errors: [new Error('用户不存在')],
            },
          });
        }
      }, 1000);
    },
    saveTeamMember() {
      this.form.validateFields((error, values) => {
        if (!error) {
          // 检查邮箱格式
          const emailCheck = this.validateEmail(values.email);
          if (!emailCheck) {
            this.form.setFields({
              email: {
                value: values.email,
                errors: [new Error('邮箱格式有误')],
              },
            });
            return;
          }
          const params = {
            inviteUserId: this.$store.state.userInfo.detail.id, // 邀请发起人
            teamId: this.initialValue.id, // 团队id
            email: values.email,
            memberRole: values.user_role, // 成员角色
            requiredApproval: this.requiredApproval2, // 是否需要审批
          };
          const inviteUrl = `${
            window.location.origin
          }/user/join?invite_code=${window.btoa(JSON.stringify(params))}`;
          this.$store.dispatch('inviteTeamMemberByEmail', {
            teamId: this.initialValue.id,
            data: {
              email: values.email,
              inviteUserId: this.$store.state.userInfo.detail.id, // 邀请发起人
              teamId: this.initialValue.id, // 团队id
              inviteUrl
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.team-add-member {
  display: flex;
  flex-direction: column;

  .form-wrapper {
    flex: 1;
    padding: 0 20px;
  }
}
</style>

<template>
  <div class="team-setting">
    <h4>团队名称</h4>
      <div class="team-name-wrapper">
        <div class="input-wrapper"> 
          <a-input 
            :value="initialValue ? initialValue.name : undefined" 
            :disabled="!enableEditTeamName" 
            @change="changeTeamName"
          />
        </div>
        <a-button @click="editTeamName" v-if="!enableEditTeamName">修改</a-button>
        <a-button @click="saveTeamName" v-if="enableEditTeamName">保存</a-button>
      </div>
      
      <h4>危险操作</h4>
      <div class="danger-wrapper">
        <ul>
          <li class="border-bottom">
            <p class="name">移交团队</p>
            <span class="desc">将团队的所有者权限移交给其他成员</span>
            <a-button @click="transferTeamOwner">移交</a-button>
          </li>
          <li>
            <p class="name">解散团队</p>
            <span class="desc">务必谨慎，解散后无法找回</span>
            <a-button @click="handleDeleteTeam">解散</a-button>
          </li>
        </ul>
      </div>
  </div>
</template>

<script>
export default {
  name: 'teamListSetting',
  props: {
    initialValue: Object,
    deleteTeam: Function,
  },
  data() {
    return {
      enableEditTeamName: false
    };
  },
  methods: {
    editTeamName() {
      this.enableEditTeamName = true;
    },
    changeTeamName(e) {
      this.initialValue.name = e.target.value;
    },
    async saveTeamName() {
      const newData = {
        id: this.initialValue.id,
        name: this.initialValue.name
      }
      const res = await this.$store.dispatch('saveTeam', newData);
      if (res.code === 0) {
        this.enableEditTeamName = false;
        this.$store.commit('setCurrentTeam', this.initialValue);
      }
    },
    // 移交团队
    transferTeamOwner() {

    },
    // 解散团队
    handleDeleteTeam() {
      const _this = this;
      const currentUser = this.$store.state.userInfo.detail;
      if (this.initialValue.owner_id === currentUser.id) {
        this.$confirm({
          title: '解散团队',
          content: '确定解散该团队?',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            _this.deleteTeam();
          },
        });
      } else {
        this.$message.error('只有团队管理者可以操作！')
      }
    }
  },
};
</script>

<style lang="scss">
.team-setting {
  padding: 15px 0;

  .team-name-wrapper {
    margin-bottom: 30px;
    padding: 20px 15px;
    background-color: var(--current-background-color2);
    position: relative;
    .input-wrapper {
      margin-right: 70px;
    }
    .ant-input-disabled {
      border: none;
    }
    button {
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -16px;
    }
  }
  .danger-wrapper {
    padding: 0 15px;
    border: 1px solid #da3928;
    border-radius: 4px;
    li {
      padding: 15px 0;
      position: relative;
      .name {
        margin-bottom: 5px;
      }
      .desc {
        font-size: 12px;
      }
      button {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -16px;
      }
    }
  }
}
</style>

<template>
  <div class="author-setting">
    <h4>授权管理</h4>
  </div>
</template>

<script>
export default {
  name: 'authorSetting',
  props: {
    
  },
  data() {
    return {
      
    }
  }
}
</script>

<style lang="scss">
  .author-setting {
    
  }
</style>

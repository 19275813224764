<template>
  <div class="plugin-setting">
    <h4>插件管理</h4>
  </div>
</template>

<script>
export default {
  name: 'pluginSetting',
  props: {
    
  },
  data() {
    return {
      
    }
  }
}
</script>

<style lang="scss">
  .plugin-setting {
    
  }
</style>

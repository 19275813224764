<template>
  <div class="team-list-project">
    <div class="text-align-right margin-bottom btns">
      <a-button icon="import" @click="importProject">
        导入项目
      </a-button>
      <a-button type="primary" icon="plus" @click="createProject">
        新建项目
      </a-button>
    </div>
    <a-empty v-if="!projects.length" />
    <draggable
      v-if="projects.length"
      v-model="projects"
      ghostClass="ghost"
      chosenClass="chosen"
      forceFallback="true"
      animation="500"
      handle=".mover"
    >
      <div
        class="team-project-item border-bottom"
        v-for="project in projects"
        :key="project.id"
      >
        <span class="mover-wrapper">
          <a-icon type="menu" class="mover" />
        </span>
        <span class="name">{{ project.name }}</span>
        <span
          class="action"
          v-if="project.owner.includes($store.state.userInfo.detail.id)"
        >
          <a-button
            type="link"
            class="select-project"
            @click="changeProject(project.id)"
          >
            <a-icon type="code" />
            <span>进入该项目接口/控制台</span>
          </a-button>
          <a-dropdown
            :trigger="['click']"
            placement="bottomRight"
            overlayClassName="setting-dropdown"
          >
            <a-button
              type="link"
              icon="ellipsis"
              @click="(e) => settingProjectItem(e, project)"
            ></a-button>
            <a-menu slot="overlay" class="extra-menu">
              <a-menu-item>
                <a @click="editProject">
                  <a-icon type="edit" />
                  <span>编辑</span>
                </a>
              </a-menu-item>
              <a-menu-item>
                <a @click="deleteConfirm">
                  <a-icon type="delete" />
                  <span>删除</span>
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </div>
    </draggable>

    <a-modal
      :title="action === 'create' ? '新建项目' : '编辑项目'"
      wrapClassName="editProjectModal"
      :width="800"
      :maskClosable="false"
      :centered="true"
      :destroyOnClose="true"
      :visible="modalVisible"
      :confirm-loading="confirmLoading"
      okText="确定"
      cancelText="取消"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="formWrapper">
        <a-form ref="editForm" :form="form">
          <a-tabs :default-active-key="activeTabKey || '1'" :animated="false">
            <a-tab-pane key="1" tab="基本信息">
              <div class="basic-info">
                <a-form-item label="项目名称">
                  <a-input
                    v-decorator="[
                      'name',
                      {
                        initialValue: this.currentProject
                          ? this.currentProject.name
                          : undefined,
                        rules: [{ required: true, message: '项目名称不能为空' }],
                      },
                    ]"
                  />
                </a-form-item>
                <a-form-item label="成员权限">
                  <member-manage-table
                    ref="memberManageTable"
                    mode="edit"
                    :initialMembers="projectMembers"
                    :changeValues="changeMembers"
                  />
                </a-form-item>
                <a-form-item label="是否公开">
                  <a-radio-group
                    v-decorator="[
                      'private',
                      {
                        initialValue: this.currentProject
                          ? this.currentProject.private
                          : 1,
                        rules: [
                          {
                            required: true,
                          },
                        ],
                      },
                    ]"
                  >
                    <a-radio :style="radioStyle" :value="1">
                      私有项目（仅对项目成员可见）
                    </a-radio>
                    <a-radio :style="radioStyle" :value="2">
                      公开项目（对所有人可见，请谨慎选择！）
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Auth">
              <div class="request-content-auth">
                <div class="left padding border-right">
                  <a-form-item label="Type">
                    <a-select
                      placeholder="请选择认证方式"
                      @change="changeRequestAuth"
                      v-decorator="[
                        'private',
                        {
                          initialValue: this.currentAuth ? this.currentAuth.type : undefined
                        },
                      ]"
                    >
                      <a-select-option
                        v-for="auth in AUTH_OPTIONS"
                        :value="auth.value"
                        :key="auth.value"
                      >
                        {{ auth.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <p>
                    <span>发送请求时，将自动生成授权标头。</span>
                    <a href="#">了解有关授权的更多信息</a>
                  </p>
                </div>
                <div class="right padding">
                  <div v-if="!currentAuth.type || currentAuth.type === AUTH.NO_AUTH" class="no-auth">
                    <p>此请求不使用任何授权。</p>
                  </div>

                  <a-form-item label="Token" v-if="currentAuth && currentAuth.type === AUTH.BEARER_TOKEN">
                    <a-input 
                      placeholder="Token"
                      v-decorator="['token', {
                        initialValue: (() => {
                          if (currentAuth.basic) {
                            const token = currentAuth.basic.find(item => item.key === 'token');
                            return token ? token.value : undefined;
                          }
                          return undefined;
                        })()
                      }]"
                    />
                  </a-form-item>

                  <a-form-item label="Username" v-if="currentAuth && currentAuth.type === AUTH.BASIC_AUTH">
                    <a-input 
                      placeholder="Username" 
                      v-decorator="['username', {
                        initialValue: (() => {
                          if (currentAuth.basic) {
                            const username = currentAuth.basic.find(item => item.key === 'username');
                            return username ? username.value : undefined;
                          }
                          return undefined;
                        })()
                      }]"
                    />
                  </a-form-item>
                  <a-form-item label="Password" v-if="currentAuth && currentAuth.type === AUTH.BASIC_AUTH">
                    <a-input-password
                      type="password"
                      placeholder="Password"
                      v-decorator="['password', {
                        initialValue: (() => {
                          if (currentAuth.basic) {
                            const password = currentAuth.basic.find(item => item.key === 'password');
                            return password ? password.value : undefined;
                          }
                          return undefined;
                        })()
                      }]"
                    />
                  </a-form-item>

                  <a-form-item label="Key" v-if="currentAuth && currentAuth.type === AUTH.API_KEY">
                    <a-input 
                      v-decorator="['key', {
                        initialValue: (() => {
                          if (currentAuth.basic) {
                            const key = currentAuth.basic.find(item => item.key === 'key');
                            return key ? key.value : undefined;
                          }
                          return undefined;
                        })()
                      }]" 
                      placeholder="Key" 
                    />
                  </a-form-item>
                  <a-form-item label="Value" v-if="currentAuth && currentAuth.type === AUTH.API_KEY">
                    <a-input 
                      v-decorator="['value', {
                        initialValue: (() => {
                          if (currentAuth.basic) {
                            const val = currentAuth.basic.find(item => item.key === 'value');
                            return val ? val.value : undefined;
                          }
                          return undefined;
                        })()
                      }]" 
                      placeholder="Value" 
                    />
                  </a-form-item>
                  <a-form-item label="Add to" v-if="currentAuth && currentAuth.type === AUTH.API_KEY">
                    <a-select 
                      v-decorator="['in', {
                        initialValue: (() => {
                          if (currentAuth.basic) {
                            const addTo = currentAuth.basic.find(item => item.key === 'in');
                            return addTo ? addTo.value : 'Header';
                          }
                          return 'Header';
                        })()
                      }]" 
                    >
                      <a-select-option value="header">Header</a-select-option>
                      <a-select-option value="query">Query Params</a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="预脚本处理">
              <pre-request-scripts 
                :rawContent="preRequestRaw"
                :rawContentType="rawContentType"
                :onChange="preRequestCodeChange"
              />
            </a-tab-pane>
            <a-tab-pane key="4" tab="后脚本处理">
              <after-request-scripts 
                :rawContent="afterRequestRaw"
                :rawContentType="rawContentType"
                :onChange="afterRequestCodeChange"
              />
            </a-tab-pane>
            <a-tab-pane key="5" tab="公共变量">
              <div class="project-variable">
                <p class="header-title">
                  这些变量特定于<b>当前项目</b>及其请求。
                </p>
                <div class="table-wrapper">
                  <div class="border-left border-right">
                    <param-table
                      :defaultParams="projectVariableContent"
                      :changeValues="changeProjectVariableValues"
                    />
                  </div>
                </div>
                <div class="tip-wrapper">
                  <p><strong>使用变量重用值并保护敏感数据</strong></p>
                  <p>
                    <span>将敏感数据存储在变量类型secret中，以在屏幕上隐藏其值。</span>
                    <router-link to="/">了解有关变量类型的更多信息</router-link>
                  </p>
                  <p>
                    <span>使用变量的当前值，以防止与团队共享敏感值。</span>
                    <router-link to="/">了解有关变量值的更多信息</router-link>
                  </p>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-form>
      </div>
    </a-modal>

  </div>
</template>

<script>
import draggable from 'vuedraggable';
import MemberManageTable from '@/components/common/MemberManageTable';
import ParamTable from '@/components/common/ParamTable';
import PreRequestScripts from '@/components/common/RequestScripts/PreRequestScripts';
import AfterRequestScripts from '@/components/common/RequestScripts/AfterRequestScripts';
import { buildProjectInfo } from '@/utils';
import { AUTH, AUTH_OPTIONS } from '@/utils/constant';

export default {
  name: 'teamListProject',
  components: {
    draggable,
    MemberManageTable,
    editor: require('vue2-ace-editor'),
    ParamTable,
    PreRequestScripts,
    AfterRequestScripts,
  },
  props: {
    projects: Array,
    changeActiveKey: Function,
    clickNewProject: Function,
    saveProject: Function,
    deleteProject: Function,
    teamMembers: Array,
    currentTeam: Object,
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'editProject' }),
      AUTH: AUTH,
      AUTH_OPTIONS: AUTH_OPTIONS,
      currentProject: null,
      modalVisible: false,
      confirmLoading: false,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      projectMembers: [],
      action: 'create',
      activeTabKey: '1',
      currentAuth: {
        type: undefined,
        content: undefined,
      },
      
      preRequestRaw: '',
      afterRequestRaw: '',
      rawContentType: 'javascript',
      editorOptions: {
        enableBasicAutocompletion: true, // 启用基本自动完成
        enableSnippets: true, // 启用代码段
        enableLiveAutocompletion: true, // 启用实时自动完成
        tabSize: 2, // 标签大小
        fontSize: 14, // 设置字号
        showPrintMargin: false, // 去除编辑器里的竖线
        showFoldWidgets: true, // 展示折叠
      },
      projectVariableContent: []
    };
  },
  methods: {
    editorInit: function() {
      require('brace/ext/language_tools'); //language extension prerequsite...
      require('brace/mode/javascript');
      require('brace/theme/eclipse');
      require('brace/snippets/javascript'); //snippet
    },
    settingProjectItem(e, project) {
      const projectInfo = buildProjectInfo(JSON.parse(JSON.stringify(project)));
      if (projectInfo.auth) {
        this.currentAuth = projectInfo.auth;
      }
      this.currentProject = projectInfo;
      this.preRequestRaw = projectInfo.pre_request_raw;
      this.afterRequestRaw = projectInfo.after_request_raw;
      this.projectVariableContent = projectInfo.variable || [];
      e.stopPropagation();
    },
    changeMembers(values) {
      this.projectMembers = values;
    },
    changeRequestAuth(value) {
      const auth = {
        type: value,
        content: this.currentAuth ? this.currentAuth.content : null,
      };
      this.currentAuth = auth;
    },
    handleOk() {
      const form = this.$refs.editForm.form;
      form.validateFields((err, values) => {
        if (!err) {
          const members = this.projectMembers
            .filter((item) => item.checked)
            .map((item) => ({
              username: item.username,
              email: item.email,
              user_id: item.user_id,
              user_role: item.user_role,
              // status: item.status || 'APPROVED',
            }));
          const project = {
            members,
            ...values,
          };
          if (this.action === 'create') {
            project['team_id'] = this.currentTeam.id;
          } else {
            project['id'] = this.currentProject.id;
          }
          this.modalVisible = false;
          this.saveProject(project, this.action);
          form.resetFields();
        }
      });
    },
    handleCancel() {
      this.modalVisible = false;
      this.confirmLoading = false;
    },
    async changeProject(value) {
      const { projects } = this.$store.state;
      const currentProject = projects.find((item) => item.id === value);
      this.$store.commit('setApiTabs', []);
      this.$store.commit('setCurrentProject', currentProject);
      await this.$store.dispatch('getCategoryList');
      this.$store.dispatch('getHistoryList');
      this.$store.commit('changeTeamManageModalVisible', false);
      this.$store.commit('setCurrentSliderNav', 'api');
    },
    importProject() {
      this.$store.commit('changeImportProjectModalVisible', true);
    },
    createProject() {
      this.action = 'create';
      const teamMembers = this.teamMembers
        .filter((item) => item.status === 'APPROVED')
        .map((item) => ({ ...item, checked: true }));
      teamMembers.forEach((member) => {
        if (member.user_role === 'owner') {
          member['disabled'] = true;
        }
      });
      this.projectMembers = teamMembers;
      this.currentProject = null;
      this.modalVisible = true;
    },
    editProject() {
      let members = this.currentProject ? this.currentProject.members : [];
      if (typeof members === 'string') {
        members = JSON.parse(members);
      }
      members = members
        ? members.map((item) => ({ ...item, checked: true }))
        : [];
      const teamMembers = this.teamMembers
        .filter((item) => item.status === 'APPROVED')
        .map((item) => ({ ...item, checked: false }));
      members = members.concat(teamMembers);
      const arr = [];
      members.forEach((member) => {
        if (!arr.find((item) => item.user_id === member.user_id)) {
          if (member.user_role === 'owner') {
            member['disabled'] = true;
          }
          arr.push(member);
        }
      });
      this.projectMembers = arr;
      this.action = 'edit';
      this.modalVisible = true;
    },
    deleteConfirm() {
      this.$confirm({
        title: '删除项目',
        content: '确定移除该项目?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          this.deleteProject(this.currentProject);
        },
      });
    },
    preRequestCodeChange(content) {
      this.preRequestRaw = content;
    },
    afterRequestCodeChange(content) {
      this.afterRequestRaw = content;
    },
    changeProjectVariableValues() {

    }
  },
};
</script>

<style lang="scss">
.team-list-project {
  .btns {
    .ant-btn {
      margin-left: 10px;
    }
  }
  .team-project-item {
    display: flex;
    padding: 15px 10px;
    align-items: center;
    .mover-wrapper {
      .mover {
        color: #999;
        cursor: pointer;
      }
    }
    .name {
      flex: 1;
      padding-left: 40px;
      white-space: nowrap; 
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .action {
      width: 40%;
      text-align: right;
    }
    .select-project {
      display: none;
    }
    &:hover .select-project {
      display: inline-block;
    }
  }
  /*选中样式*/
  .chosen {
    opacity: 0.9 !important;
    border: 1px solid #ede8e4 !important;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);
  }
  .ghost {
    border-top: 1px solid #e37348 !important;
  }
}
.editProjectModal {
  .formWrapper {
    height: 488px;
    padding: 0 10px;
    .ant-tabs-bar {
      margin: 0;
    }
    .ant-tabs-tabpane {
      // min-height: 150px;
    }
    .basic-info {
      padding: 15px 10px;
    }
    .request-content-auth {
      height: 442px;
      overflow: hidden;

      .left {
        float: left;
        width: 30%;
        height: 100%;
        box-sizing: border-box;
      }
      .right {
        float: left;
        width: 70%;
        height: 100%;
        box-sizing: border-box;
        
      }
      .no-auth {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }
    .request-pre-request-scripts,
    .request-after-request-scripts {
      height: 442px;
      .header-title {
        margin: 0 15px;
        line-height: 40px;
      }
      .editor-wrapper {
        flex: 1;
        margin: 0 10px;
        border-radius: 4px;
        overflow: hidden;
      }
    }
    .project-variable {
      display: flex;
      flex-direction: column;
      height: 430px;
      overflow-y: auto;

      .header-title {
        margin: 0 15px;
        height: 40px;
        line-height: 40px;
      }
      .table-wrapper {
        flex: 1;
      }
      .tip-wrapper {
        margin-top: 20px;
        // height: 135px;
      }
    }
  }
}
</style>

<template>
  <div class="request-pre-request-scripts">
    <div class="header-title">
      "预执行脚本"用javascript编写，并在发送请求之前运行。
    </div>
    <a-row>
      <a-col :span="18">
        <div class="editor-wrapper border">
          <editor
            ref="preRequestEditor"
            v-model="preRequestRaw"
            theme="eclipse"
            width="100%"
            :height="height || 300"
            :options="editorOptions"
            :lang="rawContentType || 'javascript'"
            @init="editorInit"
            @input="codeChange"
          />
        </div>
      </a-col>
      <a-col :span="6">
        <div class="actions-wrapper" :style="{ height: `${height || 300}px` }">
          <p class="actions-header tip-wrapper-min">了解 <a href="https://doc.apimini.com/script-manage/prev-script.html" target="_blank">预脚本处理</a></p>
          <div class="actions-list">
            <ul>
              <li></li>
            </ul>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'preRequestScripts',
  components: {
    editor: require('vue2-ace-editor'),
  },
  props: {
    rawContent: String,
    rawContentType: String,
    onChange: Function,
    height: Number
  },
  data() {
    return {
      preRequestRaw: '',
      editorOptions: {
        enableBasicAutocompletion: true, // 启用基本自动完成
        enableSnippets: true, // 启用代码段
        enableLiveAutocompletion: true, // 启用实时自动完成
        tabSize: 2, // 标签大小
        fontSize: 14, // 设置字号
        showPrintMargin: false, // 去除编辑器里的竖线
        showFoldWidgets: true, // 展示折叠
      },
      actions: [
        { key: '1', label: '设置一个全局变量', value: 'setGlobalVariable' },
        { key: '2', label: '获取一个全局变量', value: 'getGlobalVariable' },
        { key: '3', label: '删除一个全局变量', value: 'delGlobalVariable' },
      ]
    };
  },
  created() {
    if (this.rawContent) {
      this.preRequestRaw = this.rawContent;
    }
  },
  methods: {
    editorInit: function() {
      require('brace/ext/language_tools'); //language extension prerequsite...
      require('brace/mode/javascript');
      require('brace/theme/eclipse');
      require('brace/snippets/javascript'); //snippet
    },
    codeChange(content) {
      this.onChange(content);
    },
  },
};
</script>

<style lang="scss">
.request-pre-request-scripts {
  .header-title {
    margin: 10px 15px;
  }
  .editor-wrapper {
    flex: 1;
    margin: 0 10px;
    border-radius: 4px;
    overflow: hidden;
  }
  .actions-wrapper {
    display: flex;
    flex-direction: column;
    .actions-header {
      display: flex;
      height: 40px;
    }
    .actions-list {
      display: flex;
      flex: 1;
      overflow-y: auto;
      ul {
        height: 500px
      }
    }
  }
}
</style>

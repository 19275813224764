<template>
  <div class="messages-popover-wrapper">
    <a-tabs v-model="tabKey" :animated="false" @change="changeTab">
      <a-tab-pane
        v-for="tab in tabs"
        :key="tab.type" 
        :tab="tab.label"
      >
        <div class="messages-popover-content">
          <a-empty description="暂无数据"></a-empty>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    
  },
  data() {
    return {
      tabKey: 'todo',
      tabs: [
        {
          type: 'todo',
          label: '代办',
        },
        {
          type: 'notices',
          label: '通知',
        },
        {
          type: 'messages',
          label: '消息',
        },
      ]
    }
  },
  methods: {
    changeTab(key) {
      this.tabKey = key;
    }
  }
}
</script>

<style lang="scss">
.messages-popover-wrapper {
  margin: -12px -16px;
  width: 400px;
  height: 400px;

  .messages-popover-content {
    padding: 12px 16px;
  }

  .ant-tabs-nav {
    width: 100%;
  }
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    width: 33.33%;
    text-align: center;
  }
}
</style>

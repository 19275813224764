<template>
  <a-modal
    title="设置"
    wrapClassName="settings-modal"
    :width="720"
    :footer="null"
    :centered="true"
    :maskClosable="false"
    :visible="this.$store.state.settingsManageModalVisible"
    @cancel="closeSettingsManageModal"
  >
    <div class="setting-modal-container">
      <div class="left">
        <a-menu
          class="bg-color"
          mode="vertical"
          :defaultSelectedKeys="[artiveKey]"
          @click="handleClickMenu"
        >
          <a-menu-item
            v-for="menu in menus"
            :key="menu.key"
          >
            <span>{{ menu.name }}</span>
          </a-menu-item>
        </a-menu>
      </div>
      <div class="right padding-20">
        <div v-if="artiveKey === 'theme'">
          <theme-setting></theme-setting>
        </div>
        <div v-if="artiveKey === 'proxy'">
          <proxy-setting></proxy-setting>
        </div>
        <div v-if="artiveKey === 'plugin'">
          <plugin-setting></plugin-setting>
        </div>
        <div v-if="artiveKey === 'author'">
          <author-setting></author-setting>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import ThemeSetting from './ThemeSetting.vue';
import ProxySetting from './ProxySetting.vue';
import PluginSetting from './PluginSetting.vue';
import AuthorSetting from './AuthorSetting.vue';
export default {
  name: 'settingsModal',
  components: {
    ThemeSetting,
    ProxySetting,
    PluginSetting,
    AuthorSetting,
  },
  data() {
    return {
      artiveKey: 'theme',
      menus: [
        { key: 'theme', name: '外观' },
        { key: 'proxy', name: '网络代理' },
        { key: 'plugin', name: '插件管理' },
        { key: 'author', name: '软件授权' },
      ]
    };
  },
  methods: {
    closeSettingsManageModal() {
      this.$store.commit('changeSettingsManageModalVisible', false);
    },
    handleClickMenu({ key }) {
      this.artiveKey = key;
    },
  },
};
</script>

<style lang="scss">
.settings-modal {
  .setting-modal-container {
    height: 500px;
    .left {
      width: 150px;
      height: 100%;
      float: left;
      ul {
        height: 100%;
        overflow-y: auto;
      }
    }
    .right {
      margin-left: 150px;
      height: 100%;
      overflow-y: auto;
    }
  }
}
</style>

<template>
  <div class="manage-team-add manage-team-content">
    <div class="form-wrapper padding-20">
      <a-form
        class="api-edit-modal-form" 
        :form="form" 
        :hideRequiredMark="true" 
        @submit="addTeam"
      >
        <a-form-item label="添加团队">
          <a-input
            placeholder="团队名称"
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="团队成员" v-if="members">
          <member-manage-table
            ref="paramTable" 
            mode="view"
            :initialMembers="members"
            :changeValues="changeValues"
          />
        </a-form-item>
      </a-form>
      
    </div>
    <div class="btn-group border-top">
      <a-button @click="changeActiveKey('list')">返回</a-button>
      <a-button @click="debounce(addTeam)" type="primary">保存</a-button>
    </div>
  </div>
</template>

<script>
import MemberManageTable from '@/components/common/MemberManageTable';
export default {
  name: 'manageTeamAdd',
  components: {
    MemberManageTable
  },
  props: {
    changeActiveKey: Function,
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'addTeamForm' }),
      members: null
    };
  },
  mounted() {
    const userInfo = this.$store.state.userInfo.detail;
    this.members = [{
      disabled: true,
      checked: true,
      user_id: userInfo.id,
      user_role: 'owner',
      status: 'APPROVED',
      username: userInfo.username,
      email: userInfo.email,
      mobile: userInfo.mobile,
    }];
  },
  methods: {
    changeValues(values) {
      this.members = values;
    },
    addTeam() {
      this.form.validateFields(async (error, values) => {
        if (!error) {
          const params = {
            name: values.name,
            members: this.members.length ? this.members : null
          }
          const res = await this.$store.dispatch('addTeam', params);
          if (res.code === 0) {
            this.changeActiveKey('list');
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.manage-team-add {
  display: flex;
  flex-direction: column;

  .form-wrapper {
    flex: 1;
  }
}
</style>

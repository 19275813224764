<template>
  <a-modal
    :title="initialValue ? '编辑项目' : '添加项目'"
    :visible="this.$store.state.projectModalVisible"
    :destroyOnClose="true"
    :maskClosable="false"
    :footer="null"
    :centered="true"
    @cancel="hideProjectModal"
  >
    <a-form 
      class="project-edit-modal-form padding-20" 
      :form="form" 
      :hideRequiredMark="true"
      @submit="handleSubmit"
    >
      <a-form-item label="项目名称">
        <a-input
          placeholder=""
          v-decorator="[
            'name',
            {
              initialValue: initialValue ? initialValue.name : undefined,
              rules: [
                {
                  required: true,
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label=项目描述>
        <a-textarea
          placeholder=""
          :auto-size="{ minRows: 4, maxRows: 10 }"
          v-decorator="[
            'description',
            {
              initialValue: initialValue ? initialValue.description : undefined,
              rules: [
                {
                  max: 200
                },
              ],
            },
          ]"
        />
      </a-form-item>
    </a-form>
    <div class="btn-group border-top">
      <a-button @click="hideProjectModal">取消</a-button>
      <a-button type="primary" html-type="submit" @click="debounce(handleSubmit)">
        确定
      </a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'projectEditModal',
  computed: {
    initialValue() {
      return this.$store.getters.getProjectModalValues;
    },
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'projectEditModalForm' }),
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
    };
  },
  methods: {
    showProjectModal() {
      this.$store.commit('changeProjectModalVisible', true);
    },
    hideProjectModal() {
      this.$store.commit('changeProjectModalVisible', false);
    },
    handleSubmit() {
      this.form.validateFields((error, values) => {
        if (!error) {
          const newValues = {
            ...values
          }
          if (this.initialValue && this.initialValue.id) {
            newValues.id = this.initialValue.id;
          }
          this.$store.dispatch('saveProjectInfo', newValues);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.project-edit-modal-form {
  
}
</style>
